import { LocalBadge } from '@app/interfaces/insights'
import Base from './Base'

class LocalBadges extends Base {
  static async list(locationId: number): Promise<LocalBadge[]> {
    const { data } = await this.get(`/locations/${locationId}/local_badges`)

    return data
  }
}

export default LocalBadges
